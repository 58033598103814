@import "reset.css";
@import "fonts/inter.css";

$max-width-grid-columns-2: 900px;
$max-width-grid-columns-1: 600px;
$max-width-body-adjust-text-alignment-left: 500px;
$max-width-body-adjust-text-more-crammed: 400px;

:root {
	font-family: "Inter", sans-serif;
	
	--spacing: 2em;
	--label-color: #000;
	--secondary-label-color: #565656;
	--tertiary-label-color: rgba(#3C3C3C, 60%);
	--quaternary-label-color: rgb(161, 161, 161);
	
	--grouped-background-color: #F9F9F9;
	--grid-border-image: url(data-url:/assets/img/grid-corners.svg) 72 fill / 72px;
	
	--h1-size: 64px;
	--h2-size: 24px;
	--h3-size: 22px;
	--body-font-size: 24px;
	--small-font-size: 14px;
	--button-font-size: 16px;
	
	--bold-font-weight: 700;
	--medium-font-weight: 500;
	--normal-font-weight: 400;
	--light-font-weight: 300;
	
	--mark-reserved-length: 2em;
	
	--body-text-alignment: center;
	
	--z-index-mark: 1;
	--z-index-modality: 2;
	--z-index-transitioning: 3;
	
	@media (max-width: $max-width-grid-columns-2){
		--h1-size: 54px;
		--h2-size: 19px;
	}
	
	@media (max-width: $max-width-grid-columns-1){
		--h1-size: 40px;
	}
	
	@media (max-width: $max-width-body-adjust-text-alignment-left){
		--body-text-alignment: left;
	}
	
	@media (max-width: $max-width-body-adjust-text-more-crammed){
		--h1-size: 36px;
	}
	
}

@supports (font-variation-settings: normal) {
	:root {
		font-family: "InterVariable", sans-serif;
		font-optical-sizing: auto;
	}
}

html, body {
	width: 100%;
	height: 100%;
}

/* Overscroll */

html:has(dialog[open]) {
	/* remove the main scrollbar when dialog is open */
	overflow: hidden;
}

.sheet-dialog,
.sheet-container {
	overscroll-behavior: contain;
}

main {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	min-height: 100%;
	box-sizing: border-box;
	
	max-width: calc($max-width-grid-columns-2 + (var(--spacing) * 2));
	
	margin: 0 auto;
	padding: var(--spacing);
	
	font-size: var(--body-font-size);
	line-height: 1.4;
	
	// General
	
	a {
		color: inherit;
	}
	
	i, em {
		font-style: italic;
	}
	
	.amp {
		font-family: Baskerville;
		font-style: italic;
	}
	
	// Header
	& > header {
		display: flex;
		flex-direction: column;
		
		padding: var(--spacing);
		
		a.mark {
			align-self: end;
			
			background-color: rgba(255, 255, 255, 0.5);
			backdrop-filter: blur(20px);
			
			--outline: 24px;
			
			margin: calc(var(--outline) * -1);
			padding: var(--outline);
			border-radius: 999px;
			
			cursor: pointer;
		}
		
		position: fixed;
		top: 0;
		right: 0;
		
		z-index: var(--z-index-mark);
		
		box-sizing: border-box;
	}
	
	// Footer
	
	& > footer {
		color: var(--secondary-label-color);
		
		font-size: var(--small-font-size);
		text-align: center;
		
		padding: var(--spacing) 0;
		
		a {
			text-decoration: none;
			
			&:hover {
				text-decoration: underline;
			}
		}
		
		.language {
			white-space: nowrap;
			
			.label {
				
				&:before {
					content: "";
					
					display: inline-block;
					
					width: 20px;
					height: 1em;
					
					background-size: 20px 13px;
					background-repeat: no-repeat;
					background-position: center;
					
					margin-left: 0.25em;
					margin-right: 0.4em;
					margin-bottom: -2px;
				}
				
				&.english:before {
					background-image: url(/assets/img/english.svg);
				}
				
				&.spanish:before {
					background-image: url(/assets/img/spanish.svg);
				}
			}
		}
		
		* + * {
			margin-top: 0.25em;
		}
	}
	
	// Section
	
	& > section + section {
		margin-top: calc(var(--spacing) * 2);
	}
	
	section {
		text-align: var(--body-text-alignment);
		
		header {
			line-height: 1.2;
			
			// Reserve spacing for logo:
			padding-right: var(--mark-reserved-length);
			padding-left: var(--mark-reserved-length);
			
			* + * {
				margin-top: var(--spacing);
			}
			
			@media (max-width: $max-width-body-adjust-text-alignment-left) {
				text-align: left;
				padding-left: 0;
			}
			
			&.mark-spacing-adjust-exempted {
				text-align: center;
				
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		h1 {
			font-size: var(--h1-size);
			font-weight: var(--bold-font-weight);
		}
		
		h2 {
			font-size: var(--h2-size);
			font-weight: var(--medium-font-weight);
			
			color: var(--secondary-label-color);
		}
		
		h3 {
			font-size: var(--h3-size);
			font-weight: var(--medium-font-weight);
		}
		
		* + * {
			margin-top: 1em;
		}
		
		picture {
			display: flex;
			
			* + * {
				margin-top: unset;
			}
		}
		
		&.primary {
			flex-grow: 1;
			align-content: center;
		}
		
	}
	
	// Grid:
	
	.grid {
		--inter-spacing: 16px;
		--number-of-items: 3;
		
		display: flex;
		flex-wrap: wrap;
		
		gap: var(--inter-spacing);
		
		margin-top: var(--spacing);
		
		& > * {
			display: flex;
			flex-direction: column;
			
			margin: 0px;
			
			width: calc((100% - (var(--inter-spacing) * (var(--number-of-items) - 1))) / var(--number-of-items));
			
			background-color: var(--grouped-background-color);
			border-image: var(--grid-border-image);
		}
		
		@media (max-width: $max-width-grid-columns-2){
			--number-of-items: 2;
		}
		
		@media (max-width: $max-width-grid-columns-1){
			--number-of-items: 1;
		}
		
	}
	
	// Services:
	
	.services {
		text-align: center;
		justify-content: flex-end;
		
		li {
			display: flex;
			flex-direction: column;
			
			box-sizing: border-box;
			
			padding: var(--spacing);
			
			p {
				font-size: var(--small-font-size);
			}
		}
		
		aside {
			display: flex;
			flex-direction: column;
			
			align-self: end;
			justify-items: center;
			
			text-align: left;
			
			font-size: var(--small-font-size);
			background: none;
			border: none;
			
			padding: 1em;
			padding-bottom: 3.45em;
			
			box-sizing: border-box;
			
			b, strong {
				font-weight: var(--medium-font-weight);
			}
			
			.arrow {
				display: block;
				
				width: 42px;
				height: 42px;
				
				margin-left: calc(calc(var(--spacing) * -1.0) - 8px);
				
				z-index: -1;
				
				background-repeat: no-repeat;
				background-size: contain;
				background-image: url("data-url:/assets/img/arc-path.svg");
				
				align-self: start;
			}
			
			@media (max-width: $max-width-grid-columns-2){
				flex-grow: 1;
				
				.arrow {
					display: none !important;
				}
				
				p {
					text-align: var(--body-text-alignment);
				}
			}
		}
		
	}
	
	// Photos
	
	.photoshoot {
		position: relative;
		z-index: -1;
		
		img {
			height: auto;
			max-width: 100%;
		}
		
		figure {
			position: relative;
			
			&:after {
				content: "";
				
				display: block;
				
				position: absolute;
				inset: 0;
				
				z-index: 1;
				
				border-image: var(--grid-border-image);
			}
		}
		
		.attribution {
			height: 1em;
			
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			
			margin: 0;
			margin-right: -2em;
			margin-top: -1em;
			
			transform: rotate(-90deg);
			transform-origin: 100% 100%;
			text-align: left;
			
			font-size: 10px;
			background: transparent;
			
			color: var(--quaternary-label-color);
		}
	}
	
	// Case
	
	.case {
		margin-top: var(--spacing);
		margin-bottom: var(--spacing);
	}
	
	// Steps
	
	.steps {
		
		li {
			box-sizing: border-box;
			padding: var(--spacing) 1em;
			
			font-weight: var(--medium-font-weight);
			
			figure {
				position: relative;
				
				.floating {
					position: absolute;
					top: -50%;
					left: 75%;
				}
			}
			
			figure, picture {
				align-self: center;
				pointer-events: none;
			}
		}
		
		figcaption {
			display: flex;
			gap: 0.25em;
			
			align-items: center;
			justify-content: center;
			
			text-align: left;
			
			* + * {
				margin-top: unset;
			}
			
			.number {
				text-align: center;
				
				align-self: flex-start;
				align-content: center;
				justify-content: center;
				flex-shrink: 0;
				
				line-height: 1.5;
				
				margin-right: 0.15em;
				margin-top: 0.15em;
				
				vertical-align: middle;
				
				width: 1.5em;
				height: 1.5em;
				border-radius: 999px;
				
				font-size: 16px;
				font-weight: 600;
				
				color: var(--grouped-background-color);
				background-color: var(--label-color);
			}
		}
		
		
	}
	
	// Hero
	
	section.hero {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		text-align: center;
		
		--next-reveal-length: 3.5em;
		--section-space-length: calc(var(--spacing) * 2);
		
		--viewport-height: 100vh;
		--viewport-height: 100svh; /* Small viewport height for MobileSafari */
		
		--preferred-height: calc(var(--viewport-height) - var(--section-space-length) - var(--next-reveal-length));
		
		min-height: var(--preferred-height);
		min-height: min(var(--preferred-height), $max-width-grid-columns-2);
		
		* + * {
			margin-top: var(--spacing);
		}
		
		.crt {
			position: relative;
			width: 172px;
			height: 140px;
			
			.layer {
				position: absolute;
				top: 0;
				left: 0;
				
				margin: 0;
			}
			
		}
		
		h1 {
			font-size: 32px;
			font-weight: var(--normal-font-weight);
			line-height: 1.2;
			
			b, strong {
				font-weight: var(--medium-font-weight);
			}
		}
		
	}
	
	// Press:
	
	section.press {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		text-align: left;
		font-size: var(--small-font-size);
		color: var(--secondary-label-color);
		
		a {
			display: block;
			margin-top: var(--spacing);
			
			color: inherit;
			text-decoration: none;
			
			.source {
				display: block;
				margin: 0;
				padding-top: 0.15em;
				
				color: var(--quaternary-label-color);
			}
		}
		
		ul {
			
			li {
				display: flex;
				flex-direction: row;
				gap: 1em;
				
				transition: opacity 0.15s ease-out;
				
				&:before {
					content: "";
					display: inline-flex;
					
					width: 13px;
					height: 12px;
					
					background-repeat: no-repeat;
					background-image: url("data-url:/assets/img/arrow-up-right.svg");
					
					margin-top: 0.25em;
					align-self: baseline;
				}
				
				a {
					width: 100%;
					margin-top: 0px;
				}
			}
			
			
			
			&:hover {
				
				li {
					opacity: 0.5;
				}
				
				
				li:hover {
					transition-duration: 0s;
					opacity: 1;
				}
			}
			
		}
		
	}
	
	// Contact
	
	.contact nav {
		text-align: center;
	}
	
	//
	// Main animate
	// 
	
	&.booting {
		visibility: hidden;
	}
	
	&.animate {
		
		@keyframes fade-in-bottom {
			
			0% {
				opacity: 0;
				transform: scale(0.98) translateY(-5px);
				filter: blur(10px);
			}
			
			100% {
				opacity: 1;
			}
		}
		
		@keyframes fade-in-top {
			
			0% {
				opacity: 0;
				transform: scale(0.98) translateY(5px);
				filter: blur(10px);
			}
			
			100% {
				opacity: 1;
			}
		}
		
		--appear-duration: 1.5s;
		
		.mark {
			animation: fade-in-top var(--appear-duration) ease-out;
		}
		
		.hero  {
			.crt {
				animation: fade-in-top var(--appear-duration) ease-out;
			}
			
			h1 {
				animation: fade-in-bottom var(--appear-duration) ease-out;
				animation-fill-mode: backwards;
				animation-delay: 0.25s;
			}
			
		}
		
		section:not(.hero), & > footer {
			animation: fade-in-bottom var(--appear-duration) ease-out;
			animation-delay: 0.5s;
			animation-fill-mode: backwards;
		}
	}
	
	
	
	//
	// UI
	// 
	
	// Buttons:
	
	.rounded-button {
		display: inline-block;
		
		cursor: pointer;
		
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		border: none;
		font: inherit;
		font-size: var(--button-font-size);
		font-weight: var(--medium-font-weight);
		
		height: 50px;
		line-height: 50px;
		text-decoration: none;
		
		color: white;
		background: #84838B;
		border-radius: 99px;
		
		padding: 0px 24px;
		
		transition: background-color 0.25s ease-out;
		
		&:hover {
			transition-duration: 0.1s;
			background: #66656D;
		}
		
		&:active {
			background: #4B4B50;
		}
		
		&.destructive {
			background: red;
			color: white;
			
			&:hover {
				background: #EF0606;
			}
			
			&:active {
				background: #DC0606;
			}
		}
	}
	
	.plain-button {
		cursor: pointer;
		
		font-size: var(--button-font-size);
		font-weight: var(--normal-font-weight);
		
		appearance: none;
		border: none;
		background: none;
		
		color: #84838B;
		padding: 0px;
		
		min-width: 50px;
		height: 50px;
		line-height: 50px;
		
		outline: none;
		
		display: flex;
		align-items: center;
		justify-content: center;
		
		border-radius: 99px;
		
		&:hover {
			background: rgba(#84838B, 0.25);
		}
	}
	
	// Sheets:
	
	.sheet-hidden {
		display: none;
	}
	
	.sheet-dialog {
		position: fixed;
		inset: 0;
		border: 0;
		margin: 0;
		
		box-sizing: border-box;
		
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		
		overflow: hidden;
		
		z-index: var(--z-index-modality);
		
		background: none;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		padding: 1em;
		
		.sheet {
			// align-self: center;
			// justify-self: center;
		}
		
		&::backdrop {
			background: none;
		}
		
	}
	
	.sheet-transitioning-container {
		position: absolute;
		inset: 0;
		
		pointer-events: none;
		
		z-index: var(--z-index-transitioning);
	}
	
	.sheet-backdrop {
		position: absolute;
		inset: 0;
		
		// pointer-events: none;
		background-color: rgba(0, 0, 0, 0.5);
		
		opacity: 0;
		transition: 1s ease-in-out opacity;
		
		&.appear {
			opacity: 1;
		}
	}
	
	.sent-confirmation-overlay {
		position: absolute;
		overflow: hidden;
		
		inset: 0;
		
		.content-wrapper {
			position: absolute;
			
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			
			padding: 44px;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			
			color: #FFF;
			
			text-align: center;
			
			h1 {
				font-size: 32px;
				font-weight: var(--medium-font-weight);
				
				margin-bottom: 16px;
			}
			
			h2 {
				font-size: 15px;
				font-weight: var(--normal-font-weight);
				
				margin-bottom: 32px;
			}
			
			h1, h2 {
				line-height: 1.2;
			}
			
			.rounded-button {
				background: rgba(255, 255, 255, 0.15);
				
				&:hover {
					background: rgba(255, 255, 255, 0.35);
				}
				
				&:active {
					background: rgba(255, 255, 255, 0.25);
				}
			}
		}
		
		.backdrop {
			position: absolute;
			inset: 0;
			
			background: #5F5E6B;
			
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
		}
		
		&.presented {
			.backdrop {
				opacity: 1;
			}
		}
	}
	
	.delete-confirmation-overlay {
		position: absolute;
		overflow: hidden;
		
		inset: 0;
		transition: all 0.5s ease-in-out;
		
		.backdrop {
			position: absolute;
			
			inset: 0;
			background: rgba(255, 255, 255, 0.5);
			
			opacity: 0;
			transition: opacity 0.5s ease-in-out;
			
			// Prevent mask overlap due to issues on Firefox:
			@supports (not (mask-border-source: none)) {
				inset: 24px;
			}
		}
		
		.delete-button-wrapper {
			position: relative;
			
			display: inline-block;
			
			border-radius: 99px;
			overflow: hidden;
		}
		
		&.presented {
			.backdrop {
				opacity: 1;
			}
		}
	}
	
	.intermediate-progress {
		
		.sheet-content {
			opacity: 0.5;
			pointer-events: none;
			transition: opacity 0.5s ease-in;
		}
	}
	
	.sheet {		
		border: none;
		padding: 0;
		background: none;
		border-style: solid;
		border-width: 0px;
		
		line-height: 1; /* assumed for internal rendering */
		
		width: 100%;
		height: 100%;
		
		max-width: 400px;
		max-height: 400px;
		
		.sheet-container {
			position: relative;
			
			width: 100%;
			height: 100%;
			
			background-color: white;
		}
		
		.sheet-content {
			display: flex;
			flex-flow: column;
			
			width: 100%;
			height: 100%;
			
			position: relative;
			overflow-y: scroll; /* recommended */
			overflow-x: hidden;
			
			scrollbar-width: none;
			
			&::-webkit-scrollbar {
				display: none;
			}
			
			header {
				$bottom-padding: 12px;
				
				padding: 24px;
				padding-bottom: $bottom-padding;
				
				display: flex;
				justify-content: space-between;
				
				position: sticky;
				top: 0;
				
				.leading-items, .trailing-items {
					display: flex;
					gap: 12px;
				}
				
				// Fallback due to mask issues on Firefox:
				@supports (not (mask-border-source: none)) {
					.background {
						background: linear-gradient(#fff 50% , rgba(255, 255, 255, 0) 100%);
						
						&:after {
							content: "";
							
							display: block;
							position: absolute;
							
							top: 24px;
							left: 12px;
							right: 12px;
							bottom: 12px;
							
							backdrop-filter: blur(1px);
						}
					}
					
					.background .layer {
						display: none;
					}
				}
				
				.background {
					position: absolute;
					
					top: 0;
					left: 0;
					bottom: $bottom-padding * -2;
					right: 0;
					
					z-index: -1;
					
					.layer {
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						
						&:nth-child(1){
							// background: red;
							backdrop-filter: blur(1px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 12.5%,
								rgba(0, 0, 0, 1) 25%,
								rgba(0, 0, 0, 1) 37.5%,
								rgba(0, 0, 0, 0) 50%
							);
						}
						
						&:nth-child(2){
							// background: blue;
							backdrop-filter: blur(2px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 25%,
								rgba(0, 0, 0, 1) 37.5%,
								rgba(0, 0, 0, 1) 50%,
								rgba(0, 0, 0, 0) 62.5%
							);
						}
						
						&:nth-child(2){
							// background: blue;
							backdrop-filter: blur(2px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 25%,
								rgba(0, 0, 0, 1) 37.5%,
								rgba(0, 0, 0, 1) 50%,
								rgba(0, 0, 0, 0) 62.5%
							);
						}
						
						&:nth-child(3){
							// background: green;
							backdrop-filter: blur(4px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 37.5%,
								rgba(0, 0, 0, 1) 50%,
								rgba(0, 0, 0, 1) 62.5%,
								rgba(0, 0, 0, 0) 75%
							);
						}
						
						&:nth-child(4){
							// background: yellow;
							backdrop-filter: blur(8px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 50%,
								rgba(0, 0, 0, 1) 62.5%,
								rgba(0, 0, 0, 1) 75%,
								rgba(0, 0, 0, 0) 87.5%
							);
						}
						
						&:nth-child(5){
							// background: orange;
							backdrop-filter: blur(16px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 62.5%,
								rgba(0, 0, 0, 1) 75%,
								rgba(0, 0, 0, 1) 87.5%,
								rgba(0, 0, 0, 0) 100%
							);
						}
						
						&:nth-child(6){
							// background: purple;
							backdrop-filter: blur(32px);
							mask: linear-gradient(
								to top,
								rgba(0, 0, 0, 0) 75%,
								rgba(0, 0, 0, 1) 87.5%,
								rgba(0, 0, 0, 1) 100%
							);
						}
						
						
						
					}
				}
			}
			
			form {
				flex: 1;
				flex-grow:1;
				height: 100%; /* takes the visible area of the "main" div */
				
				padding: 0 24px;
				padding-bottom: 24px;
				
				label {
					display: block;
					
					font-size: 14px;
					
					margin-bottom: 0px;
					margin-top: 16px;
				}
				
				input, .textarea {
					display: block;
					
					$line-height: 28px;
					$underline-offset: 21px;
					$underline-height: 2px;
					$underline-color: rgba(0, 0, 0, 0.05);
					
					width: 100%;
					outline: none;
					
					font-size: 24px;
					line-height: 28px;
					
					appearance: none;
					border: none;
					padding: 0;
					
					resize: none;
					
					background: none;
					background-image: linear-gradient(transparent 0px,
						transparent $underline-offset,
						$underline-color $underline-offset,
						$underline-color $underline-offset + $underline-height,
						transparent $underline-offset + $underline-height);
						
					background-size: 2px $line-height;
					background-origin: 0px 0px;
					background-repeat: repeat;
				}
				
				.textarea {
					min-height: 28px * 4;
					
					padding-bottom: 28px;
					border-bottom-left-radius: 32px;
					border-bottom-right-radius: 32px;
				}
				
				.hidden {
					display: none;
				}
				
				input:-webkit-autofill,
				input:-webkit-autofill:hover, 
				input:-webkit-autofill:focus {
					$auto-fill-color: rgb(250, 255, 189);
					
					-webkit-box-shadow: 0 -5px 0px white inset, 0 -7px 0px $auto-fill-color inset, 0 0 0px 1000px #FFF inset;
				}
				
				input::placeholder,
				input:placeholder {
					opacity: 1;
					color: darkgray;
					font-weight: 400;
				}
			}
			
		}
		
	}
	
}


